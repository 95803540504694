@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans KR Light");
  src: url("fonts/NotoSansKR-Light.eot");
  src: url("fonts/NotoSansKR-Light.eot?#iefix") format("embedded-opentype"),
    url("fonts/NotoSansKR-Light.woff2") format("woff2"),
    url("fonts/NotoSansKR-Light.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans KR Regular");
  src: url("fonts/NotoSansKR-Regular.eot");
  src: url("fonts/NotoSansKR-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("fonts/NotoSansKR-Regular.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans KR Medium");
  src: url("fonts/NotoSansKR-Medium.eot");
  src: url("fonts/NotoSansKR-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/NotoSansKR-Medium.woff2") format("woff2"),
    url("fonts/NotoSansKR-Medium.woff") format("woff");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans KR Bold");
  src: url("fonts/NotoSansKR-Bold.eot");
  src: url("fonts/NotoSansKR-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/NotoSansKR-Bold.woff2") format("woff2"),
    url("fonts/NotoSansKR-Bold.woff") format("woff");
}
